body {
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shadow {
  box-shadow: 0 -4px 100px #d52b1e;
}

.imageSwiper .swiper-pagination {
  position: static !important;
  margin-top: 0px;
}

.swiper-pagination-bullet {
  margin-bottom: -50px;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.productSwiper .swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.menu {
  position: relative;
  /* display: inline-block; */
}

.mySwiper {
}

.menu.active .menu-content {
  display: block;
}

.menu svg {
  width: 30px;
}

.menu svg line {
  stroke: #54555a;
}

.menu svg rect {
  stroke: #54555a;
}

.menu:hover svg line {
  stroke: #fff;
}

.menu:hover svg rect {
  stroke: #fff;
}

.menu:hover svg path {
  stroke: transparent;
}

.menu:hover g {
  fill: #fff;
}

.menu-row svg path {
  fill: #54555a !important;
}

.menu-row:hover svg path {
  fill: #fff !important;
}

.menu-content {
  background-color: #fff;
  border: 1px solid #c8c8c8;
  min-width: 200px;
  border-radius: 10px;
}

button:focus {
  outline: none;
}

.menu-row p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -0.408px;
}

.menu-row:hover p {
  color: #fff;
}

.hotspotRow:hover p {
  color: #fff;
}

.hotspotRow:hover svg circle {
  fill: #fff !important;
}

.hotspotRow:hover svg path {
  fill: #54555a !important;
}

.roundedSupportContainer svg path {
  fill: #54555a !important;
}

.disabledButton svg path {
  fill: #54555a !important;
}

.disabledButton svg line {
  stroke: #54555a !important;
}

.disabledButton svg rect {
  stroke: #54555a !important;
}

.enabledButton svg path {
  fill: #fff !important;
}

.enabledButton svg line {
  stroke: #fff !important;
}

.enabledButton svg rect {
  stroke: #fff !important;
}

.middleContainer::-webkit-scrollbar {
  width: 12px;
  background-color: #c8c8c8;
}

/* Add scrollbar thumb */
.middleContainer::-webkit-scrollbar-thumb {
  background-color: #e1251b;
}

/* Add scrollbar corner */
.middleContainer::-webkit-scrollbar-corner {
  background-color: #c8c8c8;
}

/* Make scrollbar always visible */
.middleContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c8c8c8;
}

.buttonShadow {
  --tw-drop-shadow: drop-shadow(0 0 10px rgb(0 0 0 / 0.3));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.buttonShadowWhite {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgb(255 255 255 / 0.6));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.bottomMenu {
  background: #fff;
  height: fit-content;
  bottom: calc((100% - 336px) / 2);
  left: 15px;
  border-radius: 29px;
}

.bottomMenuRightIcon {
  background: #e1251b;
  height: fit-content;
  bottom: calc((100% - 112px) / 2);
  left: 0;
}

.hoverP:hover p {
  color: #fff;
}

.ping-count {
  animation-iteration-count: 5;
}

.section {
  width: 100%;
  height: 120px;
  /* padding: 10px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  transform-origin: 50% 50%;
  transform: perspective(200px) rotateX(66deg);
  animation: spinner-wiggle 1.2s infinite;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid #0000;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}
.loader:before {
  border-top-color: #fff;
}
.loader:after {
  border-top-color: #d52b1e;
  animation-delay: 0.4s;
}

.react-switch-bg div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.MuiTooltip-popper{
  z-index: 100000 !important;
}

.MuiTooltip-tooltip{
  background-color: #54555A !important;
  font-size: 1rem !important;
  padding: 12px 30px !important;
  box-shadow: 3px 3px 1px 0px rgb(0 0 0 / 0.5);
  margin-top: 10px !important;
}

.MuiTooltip-arrow{
  color: #54555A !important;
}

@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-fade {
  25%,
  75% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  /* .menu:hover svg path {
    stroke: #fff;
    fill: #fff !important;
  } */
  .MuiTooltip-tooltip{
    padding: 4px 20px !important;
    margin-top: 23px !important;
    font-size: 0.8rem !important;
  }
  .line-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
  }
  .react-switch-handle {
    height: 30px !important;
    width: 30px !important;
    left: 3px !important;
    top: 4px !important;
  }
  .menu svg {
    width: 30px;
  }
  .menu-row p {
    text-align: left;
  }
  .productSwiper {
    z-index: 0 !important;
  }
  .mySwiper {
    z-index: 0 !important;
  }
  .swiper-slide {
    z-index: 100;
  }
  .menu:hover .menu-content {
    display: block;
  }

  /* Hide empty scrollbar */
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  ::-webkit-scrollbar-corner {
    visibility: hidden;
  }

  /* Set scrollbar width and background color */
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #6d6d6d;
  }

  /* Add scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #a0160f;
  }

  /* Add scrollbar corner */
  ::-webkit-scrollbar-corner {
    background-color: #6d6d6d;
  }

  /* Make scrollbar always visible */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #6d6d6d;
  }
  .bottomMenu {
    border-radius: 0px;
    height: 75px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 640px) {
  .menu:last-child:hover svg path {
    fill: #fff !important;
  }
  .swiperSlideContainer p {
    color: #54555a;
  }
  .swiper-pagination-bullet-active {
    background-color: #76777a !important;
  }
  .MuiTooltip-tooltipPlacementRight {
    margin-left: 30px !important;
  }
}

.animate-zoom {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.75, 0.75);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.75, 0.75);
  }
}
